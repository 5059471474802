<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <div class="fluid" id="leaveList">
          <div class="leave-list-div">
            <v-card elevation="0" class="px-3 py-2" style="width: 100%">
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex"
                  style="
                    position: relative;
                    padding: 0 20px;
                    text-align: center;
                    margin-top: 20px;
                  "
                >
                  <h6
                    class="indigo--text"
                    style="font-weight: bold; margin: auto"
                  >
                    PENGAJUAN CUTI
                  </h6>
                </v-col>
                <v-col cols="12">
                  <div>
                    <v-toolbar
                      class="leave-list-toolbar-1"
                      flat
                      style="height: 60px; border-radius: 10px 10px 0 0"
                    >
                      <router-link
                        v-if="permission.create_perm === 1"
                        to="/hr/leave/submission"
                      >
                        <v-btn
                          outlined
                          elevation="1"
                          color="indigo"
                          class="indigo--text font-weight-bold"
                          style="font-size: 12px; margin-top: 5px"
                          :disabled="loading"
                        >
                          Pengajuan Cuti
                        </v-btn>
                      </router-link>

                      <!-- <div
                        flat
                        style="
                          border-radius: 10px 10px 0 0;
                          border-bottom: 1px solid #e0e0e0;
                        "
                      > -->
                      <v-btn
                        v-if="
                          getUserProfile.level.find(
                            ({ id }) =>
                              id == appLevel.adminMaster ||
                              id == appLevel.administrator
                          ) !== undefined
                        "
                        outlined
                        rounded
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="
                          font-size: 12px;
                          position: relative;
                          margin-left: 10px;
                        "
                        @click="exportData"
                        :disabled="loading"
                        >Export</v-btn
                      >
                      <!-- </div> -->
                      <div
                        class="leave-list-toolbar-1"
                        style="margin-left: auto; width: 180px"
                      >
                        <v-text-field
                          class="attendance-start-date ml-1 mr-2"
                          dense
                          label="Start Date"
                          type="date"
                          name="startdate"
                          style="height: 30px"
                          step="1"
                          v-model="paramAPI.start_date"
                          @change="startDateWatcher"
                          outlined
                          clearable
                        />
                      </div>

                      <div
                        class="leave-list-toolbar-1"
                        style="margin-left: 10px; width: 180px"
                      >
                        <v-text-field
                          class="attendance-start-date ml-1 mr-2"
                          dense
                          label="End Date"
                          type="date"
                          name="enddate"
                          style="height: 30px"
                          step="1"
                          v-model="paramAPI.end_date"
                          @change="endDateWatcher"
                          outlined
                          clearable
                        />
                      </div>
                    </v-toolbar>
                    <v-toolbar
                      class="leave-list-toolbar-1"
                      flat
                      style="height: 60px; border-radius: 10px 10px 0 0"
                    >
                      <div style="width: 150px">
                        <v-select
                          :item-disabled="modeDisable"
                          label="Tipe Pengajuan"
                          v-model="paramAPI.mode"
                          :items="dropdown.user_type"
                          style="position: relative; top: 15px; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          outlined
                          @change="modeWatcher"
                        ></v-select>
                      </div>
                      <!-- <div>
                    <v-text-field
                      style="height:38px; margin-left:20px;"
                      label="Tgl. Pengajuan"
                      dense
                      outlined
                      type="date"
                      name="date"
                      step="1"
                      clearable
                      v-model="paramAPI.date"
                    />
                  </div> -->
                      <div style="width: 150px; margin-left: 20px">
                        <v-select
                          label="Status"
                          v-model="paramAPI.status_id"
                          :items="dropdown.status"
                          style="position: relative; top: 15px; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          clearable
                          outlined
                          @change="statusWatcher"
                        ></v-select>
                      </div>
                      <div
                        class="leave-list-toolbar-1"
                        style="width: 150px; margin-left: 20px"
                      >
                        <v-select
                          label="Jenis Cuti"
                          v-model="paramAPI.type_id"
                          :items="dropdown.leave_type"
                          style="position: relative; top: 15px; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          clearable
                          outlined
                          @change="typeWatcher"
                        ></v-select>
                      </div>
                      <div
                        class="leave-list-toolbar-1"
                        style="width: 150px; margin-left: 20px"
                      >
                        <v-select
                          label="Company"
                          v-model="paramAPI.company_id"
                          :items="dropdown.company"
                          style="position: relative; top: 15px; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          clearable
                          outlined
                          :disabled="isDisableCompanyDropdown"
                          @change="companyWatcher"
                        ></v-select>
                      </div>
                      <div style="width: 150px; margin-left: 20px">
                        <v-select
                          label="Department"
                          v-model="paramAPI.department_id"
                          :items="dropdown.department"
                          style="position: relative; top: 15px; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          clearable
                          outlined
                          @change="departmentWatcher"
                        ></v-select>
                      </div>
                      <div class="leave-list-toolbar-1" style="width: 300px">
                        <v-text-field
                          v-model="paramAPI.keyword"
                          label="cari pengajuan"
                          type="search"
                          outlined
                          dense
                          append-icon="mdi-magnify"
                          style="
                            position: relative;
                            top: 15px;
                            margin-left: 10px;
                          "
                          @keyup.enter="searchEnter"
                          :disabled="loading"
                        ></v-text-field>
                      </div>
                    </v-toolbar>
                  </div>

                  <v-toolbar
                    class="leave-list-toolbar-2"
                    flat
                    style="
                      height: 60px;
                      border-radius: 10px 10px 0 0;
                      border-bottom: 1px solid #e0e0e0;
                    "
                  >
                    <v-select
                      label="Company"
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                        width: 150px;
                        margin-right: 5px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                      :disabled="isDisableCompanyDropdown"
                      @change="companyWatcher"
                    ></v-select>
                    <v-select
                      label="Department"
                      v-model="paramAPI.department_id"
                      :items="dropdown.department"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                        width: 150px;
                        margin-left: 5px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                      @change="departmentWatcher"
                    ></v-select>
                  </v-toolbar>
                  <v-toolbar
                    class="leave-list-toolbar-2"
                    flat
                    style="
                      height: 60px;
                      border-radius: 10px 10px 0 0;
                      border-bottom: 1px solid #e0e0e0;
                    "
                  >
                    <div>
                      <v-text-field
                        class="attendance-start-date mr-2"
                        dense
                        label="Start Date"
                        type="date"
                        name="startdate"
                        style="height: 30px"
                        step="1"
                        v-model="paramAPI.start_date"
                        @change="startDateWatcher"
                        outlined
                        clearable
                      />
                    </div>

                    <div>
                      <v-text-field
                        class="attendance-start-date mr-2"
                        dense
                        label="End Date"
                        type="date"
                        name="enddate"
                        style="height: 30px"
                        step="1"
                        v-model="paramAPI.end_date"
                        @change="endDateWatcher"
                        outlined
                        clearable
                      />
                    </div>
                  </v-toolbar>
                  <v-toolbar
                    class="leave-list-toolbar-2"
                    flat
                    style="
                      height: 60px;
                      border-radius: 10px 10px 0 0;
                      border-bottom: 1px solid #e0e0e0;
                    "
                  >
                    <div style="width: 150px">
                      <v-select
                        label="Jenis Cuti"
                        v-model="paramAPI.type_id"
                        :items="dropdown.leave_type"
                        style="position: relative; top: 15px; font-size: 12px"
                        item-text="name"
                        item-value="id"
                        return-id
                        dense
                        clearable
                        outlined
                        @change="typeWatcher"
                      ></v-select>
                    </div>
                  </v-toolbar>
                  <v-toolbar
                    class="leave-list-toolbar-2"
                    flat
                    style="
                      height: 60px;
                      border-radius: 10px 10px 0 0;
                      border-bottom: 1px solid #e0e0e0;
                    "
                  >
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="Cari pengajuan"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      :disabled="loading"
                      style="position: relative; top: 15px; width: 10px"
                    ></v-text-field>
                  </v-toolbar>
                </v-col>

                <v-col cols="12" style="width: 100%">
                  <v-data-table
                    v-if="result.length > 0 || !skeleton_loading"
                    mobile-breakpoint="0"
                    fixed-header
                    height="60vh"
                    :headers="headers"
                    style="cursor: pointer"
                    :items="result"
                    :loading="loading"
                    @click:row="rowClick"
                    item-key="id"
                    :options.sync="options"
                    :footer-props="{
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      itemsPerPageOptions: [1, 3, 5, 10, 15, 100, 10000]
                    }"
                    :server-items-length="totalData"
                    :page="paramAPI.page"
                    @update:page="updatePage"
                    :items-per-page="paramAPI.itemsPerPage"
                    @update:items-per-page="updateItemPerPage"
                  >
                    <template v-slot:[`item.employee`]="{ item }">
                      <div
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ item.employee != null ? item.employee.name : '-' }}
                      </div>
                    </template>
                    <template v-slot:[`item.company`]="{ item }">
                      <div
                        v-if="item.company !== null"
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ item.company.name }}
                      </div>
                    </template>
                    <template v-slot:[`item.department`]="{ item }">
                      <div
                        v-if="item.department !== null"
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ item.department.name }}
                      </div>
                    </template>
                    <template v-slot:[`item.status_leave`]="{ item }">
                      <div
                        :style="`text-transform:uppercase; font-size:14px; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                          item.status_leave
                        )};`"
                      >
                        {{ requestStatus(item.status_leave) }}
                      </div>
                    </template>
                    <template v-slot:[`item.leave_type`]="{ item }">
                      <div
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{
                          item.leave_type != null ? item.leave_type.name : '-'
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.request_leave_date`]="{ item }">
                      <div
                        @click.stop
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <div class="leave-date-col">
                          <div style="display: flex; flex-direction: row">
                            <v-chip
                              small
                              label
                              v-for="(items, index) in item.request_leave_date"
                              :key="index"
                              style="
                                margin: 0 5px 0 5px;
                                font-size: 10px;
                                margin-bottom: 3px;
                              "
                            >
                              {{
                                items.start_date
                                  .split('-')
                                  .reverse()
                                  .join('-') +
                                ' s/d ' +
                                items.end_date.split('-').reverse().join('-')
                              }}
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-data-table>
                  <v-skeleton-loader
                    v-else
                    style="width: 100%"
                    type="table-heading, table-tbody, table-tfoot"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leave',
  data: () => ({
    skeleton_loading: true,
    dialog: false,
    config: require('../../../services/config.json')[env],
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    build: process.env.VUE_APP_BUILD_TYPE,
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'name',
      sortType: 'asc',
      company_id: null,
      department_id: null,
      status_id: null,
      type_id: null,
      mode: 1,
      date: null,
      start_date: '',
      end_date: ''
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'No. Pengajuan',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department/ Section',
        value: 'department',
        align: 'left',
        sortable: false
      },
      // {
      //   text: 'Tgl. Request',
      //   value: 'submission_date',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Jenis Cuti',
        value: 'leave_type',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Cuti',
        value: 'request_leave_date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Hari',
        value: 'total_requested_leave',
        align: 'left',
        sortable: false
      },
      // {
      //   text: 'Dibuat Oleh',
      //   value: 'created_by_name',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Status',
        value: 'status_leave',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    dropdown: {
      status: [],
      department: [],
      type: [
        {
          id: 1,
          name: 'Normal'
        },
        {
          id: 2,
          name: 'Back date'
        }
      ],
      category: [],
      user_type: [],
      leave_type: [],
      company: []
    },
    enableWatcher: false
  }),

  mounted() {
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  computed: {
    /* 'getDropdownPlant' */
    ...mapGetters(['getUserProfile', 'getLeavePageQuery'])
  },
  watch: {},
  methods: {
    // ...mapActions(['dropdownPlant']),
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setLeavePageQuery']),

    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = 0
      } else {
        this.paramAPI.company_id = Number(
          this.getUserProfile.employee.company.plant_id
        )
      }
      this.enableWatcher = false
      // this.paramAPI.department_id = this.getUserProfile.employee.department_id

      // var level = null
      if (
        this.getUserProfile.level.find(
          ({ id }) => id == this.appLevel.adminMaster
        ) !== undefined
      ) {
        this.paramAPI.mode = ''
      }

      if (this.getLeavePageQuery !== null) {
        this.paramAPI = this.getLeavePageQuery
      }
      setTimeout(async () => {
        await this.getDataFromApi()
        await this.initDropdown()
        this.enableWatcher = true
      }, 200)
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    async companyWatcher(v) {
      this.paramAPI.company_id = v
      if (this.enableWatcher) {
        this.resetSomeParam()
        this.dropdownDepartment()
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async departmentWatcher(v) {
      this.paramAPI.department_id = v
      if (this.enableWatcher) {
        this.resetSomeParam()
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async modeWatcher(v) {
      this.paramAPI.mode = v
      if (this.enableWatcher) {
        this.resetSomeParam()
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async typeWatcher(v) {
      this.paramAPI.type_id = v
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    async statusWatcher(v) {
      this.paramAPI.status_id = v
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },

    dropdownUserType() {
      if (
        this.getUserProfile.level.find(
          ({ id }) => id == this.appLevel.adminMaster
        ) !== undefined
      ) {
        this.dropdown.user_type = [
          {
            id: '',
            name: 'Semuanya'
          },
          {
            id: 1,
            name: 'Saya sendiri'
          },
          {
            id: 2,
            name: 'Bawahan saya'
          },
          {
            id: 4,
            name: 'Department'
          }
        ]
      } else {
        this.dropdown.user_type = [
          {
            id: 1,
            name: 'Pribadi'
          },
          {
            id: 2,
            name: 'Bawahan saya'
          },
          {
            id: 4,
            name: 'Department'
          }
        ]
      }
    },
    dropdownStatus() {
      this.dropdown.status = [
        {
          id: '-2',
          name: 'Canceled'
        },
        {
          id: '-1',
          name: 'Rejected'
        },
        {
          id: '0',
          name: 'Draft'
        },
        {
          id: '1',
          name: 'Waiting Approval'
        },
        // {
        //   id: '2',
        //   name: 'Waiting Approval (HRD)'
        // },
        {
          id: '3',
          name: 'Approved'
        }
      ]
    },
    async initDropdown() {
      this.dropdownUserType()
      this.dropdownStatus()
      this.dropdownLeaveType()
      this.getDropdownCompany()
      await this.dropdownDepartment()
    },
    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi()
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}employee/leave/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&mode=${this.paramAPI.mode}`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      }
      if (this.paramAPI.status_id !== null) {
        url = url + `&status_leave=${this.paramAPI.status_id}`
      }
      if (this.paramAPI.type_id !== null) {
        url = url + `&leave_type_id=${this.paramAPI.type_id}`
      }
      if (this.paramAPI.start_date !== null) {
        url = url + `&start_date=${this.paramAPI.start_date}`
      }
      if (this.paramAPI.end_date !== null) {
        url = url + `&end_date=${this.paramAPI.end_date}`
      }
      this.setLeavePageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      setTimeout(() => {
        this.$router.push(`/hr/leave/detail/${pItem.id}`)
      }, 100)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async dropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.paramAPI.company_id}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownLeaveType() {
      await axios
        .get(`${this.hrsApi}master/universal/leavetype/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.leave_type = res.data.data)
          }
          return (this.dropdown.leave_type = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.leave_type = [])
        })
    },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async exportData() {
      const arrData = this.result
      this.exportNow(arrData)
    },
    exportNow(selectedData) {
      console.log('resul >>>>', selectedData)
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          nik: selectedData[i].employee != null ? selectedData[i].employee.nik : '-',
          employee_name: selectedData[i].employee != null ? selectedData[i].employee.name : '-',
          request_no: selectedData[i].request_no || '',
          company:
            selectedData[i].company !== null
              ? selectedData[i].company.name
              : '-',
          department:
            selectedData[i].department !== null
              ? selectedData[i].department.name
              : '-',
          leave_type:
            selectedData[i].leave_type.name !== null
              ? selectedData[i].leave_type.name
              : '-',
          request_date: JSON.stringify(selectedData[i].request_leave_date),
          total_date: selectedData[i].total_requested_leave,
          status: this.requestStatus(selectedData[i].status_leave),
          reason: selectedData[i].leave_reason,
          created_at: selectedData[i].created_at
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'rekapan_cuti.xlsx')
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString('id')
        return local
      }
    },
    requestStatus(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'WAITING APPROVAL'
        case 3:
          return 'APPROVED'
        default:
          return ''
      }
    },
    modeDisable(item) {
      if (
        this.getUserProfile.level.find(
          ({ id }) => id == this.appLevel.adminMaster
        ) !== undefined
      ) {
        return false
      } else {
        return item.id === ''
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'orange'
        case 3:
          return 'green'
        default:
          break
      }
    },

    startDateWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    endDateWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    }
  }
}
</script>
<style lang="scss">
#leaveList {
  position: relative;
  .leave-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .leave-list-toolbar-1 {
      .leave-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .leave-list-toolbar-2 {
      display: none;
    }
    .leave-date-col {
      display: flex;
      flex-direction: row;
      width: 260px;
      overflow: auto;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        // display: none;
        // position: absolute;
        // bottom: 15px;
        height: 3px;
      }
      &::-webkit-scrollbar-track-piece {
        box-shadow: 0 0 1px grey;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(184, 182, 182);
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  #leaveList {
    .leave-list-div {
      .leave-list-toolbar-1 {
        .leave-list-toolbar-1 {
          display: none;
        }
      }
      .leave-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
